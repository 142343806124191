export const environment = {
    production: false,
    brands: {
        enelx: {
            baseUrl: '/',
        },
    },
    defaults: {
        brand: 'enelx',
        locale: 'en-us',
    },
    base_url: "http://premo-service.preprd.denms.net:8200/",
    x_api_key: ''
};